<template>
    <!-- Timepicker Для выбора времени -->
    <div class="procedure-time-picker">
        <h2 class="window-title">Выберите Время</h2>
        <v-spacer></v-spacer>
        <div class="timepicker-body">
            <!-- Выбор часов-->
            <div class="timepicker-body__selector">
                <!-- UP -->
                <v-btn
                class="btn-selector"
                density="compact"
                @click="hourUp"
                >
                    <v-icon 
                    icon="mdi-menu-up"
                    ></v-icon>
                </v-btn>

                <!-- TIME INPUT -->
                <v-text-field
                class="input-selector"
                type="text" 
                :placeholder="'00'"
                :model-value="selectedHour"
                v-maska:[store.optionsMaskTime]
                @update:model-value="(e) => selectHour(e)"
                :hide-details="true"
                >
                </v-text-field>


                <!-- DOWN -->
                <v-btn
                class="btn-selector"
                density="compact"
                @click="hourDown"
                >
                    <v-icon 
                    icon="mdi-menu-down"
                    ></v-icon>
                </v-btn>
            </div>

            <h1>:</h1>

            <!-- Выбор минут -->
            <div class="timepicker-body__selector">
                <!-- UP -->
                <v-btn
                class="btn-selector"
                density="compact"
                @click="minuteUp"
                >
                    <v-icon 
                    icon="mdi-menu-up"
                    ></v-icon>
                </v-btn>

                <!-- TIME INPUT -->
                <v-text-field
                class="input-selector"
                type="text" 
                :placeholder="'00'"
                v-maska:[store.optionsMaskTime]
                :model-value="selectedMinute"
                @update:model-value="(e) => selectMinutes(e)"
                :hide-details="true"
                :prepend-inner-icon="undefined"
                >
                </v-text-field>


                <!-- DOWN -->
                <v-btn
                class="btn-selector"
                density="compact"
                @click="minuteDown"
                >
                    <v-icon 
                    icon="mdi-menu-down"
                    ></v-icon>
                </v-btn>
            </div>
        </div>
        <div class="timepicker-body__actions">

            <!-- ВЫБРАТЬ -->
            <v-btn 
            class="timepicker-body__confirm"
            variant="elevated"
            color="var(--bg-color-default)"
            style="color: var(--color-white); font-weight: 400; font-size: 0.8rem"
            :disabled="selectedHour === null || selectedMinute === null"
            density="comfortable"
            @click="selectTime"
            >
                Выбрать
            </v-btn>

            <!-- СБРОСИТЬ ИЗМЕНЕНИЯ -->
            <v-btn 
            class="timepicker-body__reset"
            variant="tonal"
            style="color: red; font-weight: 400;"
            density="comfortable"
            @click="resetChanges"
            >
                <v-icon icon="mdi-reload"></v-icon>
            </v-btn>
        </div>
        <v-spacer></v-spacer>

    </div>
</template>

<script setup lang="ts">
import useMainStore from '@/store/mainStore';
import { ref, defineEmits, defineProps } from 'vue';

const store = useMainStore();

// ============================   PROPS   ==============================
const props = defineProps<{
  type: string;
}>();

// ============================   EMITS   ==============================
const emit = defineEmits<{
    selectTime: [time: string | null];
}>();

// ============================   DATA   ==============================
const selectedHour = ref<string | null>('00');
const selectedMinute = ref<string | null>('00');


// =================================================   METHODS   =========================================
function resetChanges() {
    selectedHour.value = '00';
    selectedMinute.value = '00';

    //remove date from localstorage when cleared
    const prevFilter = localStorage.getItem('procedures-filter') ? JSON.parse(localStorage.getItem('procedures-filter')!) : {};
    if(props.type === "from") {
      localStorage.setItem('procedures-filter', JSON.stringify({ ...prevFilter, timeFrom: null }));
    }else {
      localStorage.setItem('procedures-filter', JSON.stringify({ ...prevFilter, timeTo: null }));
    }
}

function selectTime() {
    try {
        if(selectedHour.value !== null || selectedMinute.value !== null) {
            const readyTime = `${selectedHour.value}:${selectedMinute.value}`;
            emit('selectTime', readyTime);
        } else return;
    } catch (err) {
        throw new Error(`components/procedure/procedureTimePicker.vue: selectTime => ${err}`);
    }
}

// =================================================   HOURS   =========================================
// Обработчик ввода часов
function selectHour(e: string) {
    if(e.length > 2) {
        selectedHour.value = e.slice(1);
    }
    if(+e > 23) {
        selectedHour.value = e.slice(1)
        e = selectedHour.value;
        selectedHour.value = '23';
    }
    if(+e < 10 && +e !== 0) {
        selectedHour.value = '0' + e;
    }
    else if(+e === 0) {
        selectedHour.value = null;
    }
}

// Выбрать на один час больше
function hourUp() {
    if(selectedHour.value) {
        let entry = +selectedHour.value;
        entry++;
        if(entry > 23) {
            return;
        }
        if(entry < 10) {
            selectedHour.value = '0' + entry;
        }
        else if(entry >= 10) {
            selectedHour.value = entry + '';
        }

    }
}

// Выбрать на один час меньше
function hourDown() {
    if(selectedHour.value) {
        let entry = +selectedHour.value;
        entry--;
        if(entry <= 0) {
            selectedHour.value = '00';
            return;
        }
        if(entry < 10) {
            selectedHour.value = '0' + entry;
        }
        else if(entry >= 10) {
            selectedHour.value = entry + '';
        }

    }
}

// =================================================   MINUTES   =========================================
// Обработчик ввода минут
function selectMinutes(e: string) {
    if(e.length > 2) {
        selectedMinute.value = e.slice(1);
    }
    if(+e > 59) {
        selectedMinute.value = e.slice(1)
        e = selectedMinute.value;
        selectedMinute.value = '59';
    }
    if(+e < 10 && +e !== 0) {
        selectedMinute.value = '0' + e;
    }
    
    else if(+e === 0) {
        selectedMinute.value = null;
    }
}

// Выбрать на одну минуту больше
function minuteUp() {
    if(selectedMinute.value) {
        let entry = +selectedMinute.value;
        entry++;
        if(entry > 59) {
            return;
        }
        if(entry < 10) {
            selectedMinute.value = '0' + entry;
        }
        else if(entry >= 10) {
            selectedMinute.value = entry + '';
        }

    }
}

// Выбрать на одну минуту меньше
function minuteDown() {
    if(selectedMinute.value) {
        let entry = +selectedMinute.value;
        entry--;
        if(entry <= 0) {
            selectedMinute.value = '00';
            return;
        }
        if(entry < 10) {
            selectedMinute.value = '0' + entry;
        }
        else if(entry >= 10) {
            selectedMinute.value = entry + '';
        }

    }
}

</script>

<style scoped>
.procedure-time-picker {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.window-title {
    color: var(--color-dark);
    font-size: 18px;
    margin-top: 0.4rem;
    text-align: center;
}
.timepicker-body {
    display: flex;
    align-items: center;
}
.timepicker-body__selector {
    display: flex;
    flex-direction: column;
    width: max-content;
    height: max-content;
    align-items: center;
    justify-content: center;
}
.input-selector {
    width: 70px !important; 
    margin: 2px 10px;
}
.timepicker-body__actions {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem !important;
}
.timepicker-body__confirm {
    width: 60%;
}
.timepicker-body__reset {
    width: 10%;
    margin-left: 0.5rem;
}
.btn-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}
</style>
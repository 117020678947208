<template>
  <div class="procedures-filter-panel">
    <!-- Ввод имени пациента -->
    <v-combobox class="filter-input" v-model="selectedPatientName" :items="listPatientNames" clearable
      label="ФИО пациента" variant="outlined" @update:model-value="(value: string | null) => selectPatientName(value)"
      :loading="isLoadingFilteringPatient" :density="'comfortable'" :hide-details="true"></v-combobox>

    <!-- Ввод Названия препарата -->
    <v-combobox class="filter-input" v-model="selectedDragName" :items="listDrugNames" clearable
      label="Название раствора" variant="outlined" @update:model-value="(value: string | null) => selectDrugName(value)"
      :loading="isLoadingFilteringDrug" :density="'comfortable'" :hide-details="true"></v-combobox>

    <!-- Выбор Ответственного по процедуре -->
    <v-combobox class="filter-input" v-model="selectedResponsible" :items="listResponsibleNames" clearable
      label="Ответственный" variant="outlined"
      @update:model-value="(value: string | null) => selectResponsibleName(value)"
      :loading="isLoadingFilteringResponsible" :density="'comfortable'" :hide-details="true"></v-combobox>

    <!-- Выбор Started From процедуры -->
    <procedureDatepickerComp :label-default="'Начало (от)'"
      @select-date="(date: number | null) => selectDateTimeFrom(date, null)"
      @select-time="(time: number | null) => selectDateTimeFrom(null, time)"
      @clear-filter-field="emit('selectDateTimeFrom', null)"
      type="from"/>

    <!-- Выбор Ended To процедуры -->
    <procedureDatepickerComp :label-default="'Окончание (до)'"
      @select-date="(date: number | null) => selectDateTimeTo(date, null)"
      @select-time="(time: number | null) => selectDateTimeTo(null, time)"
      @clear-filter-field="emit('selectDateTimeTo', null)"
      type="to"/>

    <!-- Выбор Активности процедуры -->
    <v-select class="filter-input" v-model="selectedStatus" :items="listStatus" :density="'comfortable'"
      variant="outlined" @update:model-value="(status: string) => selectStatus(status)" label="Статус"
      :hide-details="true"></v-select>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, watch, onMounted } from 'vue';
import procedureDatepickerComp from './procedureDatepickerComp.vue';
import useMainStore from '../../store/mainStore';

const store = useMainStore();

// ==========================   PROPS   ==================================
const props = defineProps<{
  listPatientNames: Array<string | undefined> | [];
  listDrugNames: Array<string | undefined> | [];
  listResponsibleNames: Array<string | undefined> | [];
}>();

// ==========================   EMIT   ==================================
const emit = defineEmits<{
  selectDrugName: [drugName: string | null];
  selectPatientName: [patientName: string | null];
  selectResponsibleName: [responsibleName: string | null];
  selectStatus: [isActive: boolean];
  selectDateTimeFrom: [timestamp: number | null];
  selectDateTimeTo: [timestamp: number | null];
}>();

// ==========================   DATA   ==================================
const isLoadingFilteringDrug = ref<boolean>(false);
const isLoadingFilteringPatient = ref<boolean>(false);
const isLoadingFilteringResponsible = ref<boolean>(false);
// ============================   v-model data  ==========================
const selectedPatientName = ref<string | null>(null);
const selectedDragName = ref<string | null>(null);
const selectedResponsible = ref<string | null>(null);
const selectedStatus = ref<string>("В процессе");
// ==========================================================================
const listPatientNames = ref<Array<string | undefined>>([]);
const listDrugNames = ref<Array<string | undefined>>([]);
const listResponsibleNames = ref<Array<string | undefined>>([]);
const listStatus = ref<Array<string>>(['В процессе', 'Завершенные']);
const payload = store.LazyLoading(300);
const selectedDateFrom = ref<string | null>(null);
const selectedTimeFrom = ref<string | null>(null);
const selectedDateTo = ref<string | null>(null);
const selectedTimeTo = ref<string | null>(null);

onMounted(() => {
  const proceduresFilter = localStorage.getItem('procedures-filter') ? JSON.parse(localStorage.getItem('procedures-filter')!) : {};
  //restore only values, not filters
  selectedStatus.value = proceduresFilter.isActive === undefined ? "В процессе" : proceduresFilter.isActive === true ? "В процессе" : "Завершенные";
  selectedDragName.value = proceduresFilter.drugName;
  selectedPatientName.value = proceduresFilter.patientName;
  selectedResponsible.value = proceduresFilter.responsibleName;
})

// ==========================   METHODS   ==================================
// Функция для выбора Статуса  в списке
function selectStatus(status: string) {
  try {
    const prevFilter = localStorage.getItem('procedures-filter') ? JSON.parse(localStorage.getItem('procedures-filter')!) : {};
    localStorage.setItem('procedures-filter', JSON.stringify({ ...prevFilter, isActive: (status === "В процессе" ? true : false) }));

    if (status === listStatus.value[0]) {
      emit('selectStatus', true);
    } else if (status === listStatus.value[1]) {
      emit('selectStatus', false);
    }
  } catch (err) {
    throw new Error(`components/procedure/procedureFilterPanelComp: selectStatus  => ${err}`);
  }
}

// Функция для выбора Названия Препарата в списке
function selectDrugName(name: string | null) {
  try {
    const prevFilter = localStorage.getItem('procedures-filter') ? JSON.parse(localStorage.getItem('procedures-filter')!) : {};
    localStorage.setItem('procedures-filter', JSON.stringify({ ...prevFilter, drugName: name }));

    isLoadingFilteringDrug.value = true;
    payload(name, (entries) => {
      let currentMessage: string | null = entries[entries.length - 1];
      if (currentMessage === null) currentMessage = '';
      isLoadingFilteringDrug.value = false;
      emit('selectDrugName', currentMessage);
    });
  } catch (err) {
    throw new Error(`components/procedure/procedureFilterPanelComp: selectDrugName  => ${err}`);
  }
}

// Функция для выбора Имени Пациента в списке
function selectPatientName(name: string | null) {
  try {
    const prevFilter = localStorage.getItem('procedures-filter') ? JSON.parse(localStorage.getItem('procedures-filter')!) : {};
    localStorage.setItem('procedures-filter', JSON.stringify({ ...prevFilter, patientName: name }));

    isLoadingFilteringPatient.value = true;
    payload(name, (entries) => {
      let currentMessage: string | null = entries[entries.length - 1];
      if (currentMessage === null) currentMessage = '';
      isLoadingFilteringPatient.value = false;
      emit('selectPatientName', currentMessage);
    });
  } catch (err) {
    throw new Error(`components/procedure/procedureFilterPanelComp: selectPatientName  => ${err}`);
  }
}

// Функция для выбора Имени Ответственного в списке
function selectResponsibleName(name: string | null) {
  try {
    const prevFilter = localStorage.getItem('procedures-filter') ? JSON.parse(localStorage.getItem('procedures-filter')!) : {};
    localStorage.setItem('procedures-filter', JSON.stringify({ ...prevFilter, responsibleName: name }));

    isLoadingFilteringResponsible.value = true;
    payload(name, (entries) => {
      let currentMessage: string | null = entries[entries.length - 1];
      if (currentMessage === null) currentMessage = '';
      isLoadingFilteringResponsible.value = false;
      emit('selectResponsibleName', currentMessage);
    });
  } catch (err) {
    throw new Error(`components/procedure/procedureFilterPanelComp: selectResponsibleName  => ${err}`);
  }
}

// Функция для выбора времени dateFrom (started from)
function selectDateTimeFrom(date: number | null, time: number | null) {
  try {
    if (date !== null) {
      selectedDateFrom.value = store.timestampFormat(date, 'DD/MM/YYYY');
    }
    if (time !== null) {
      selectedTimeFrom.value = store.timestampFormat(time, 'HH:mm');
    }
    // Если при выполнении этой функции все является null то происходит полное обнуление фильтра
    let readyTimestamp;
    if (date === null && time === null && selectedDateFrom.value === null && selectedTimeFrom.value === null) {
      return emit('selectDateTimeFrom', readyTimestamp);
    }
    if (time === null) {
      const readyPreview = `${selectedDateFrom.value}`;
      readyTimestamp = store.parseTimeString(readyPreview, 'DD/MM/YYYY');
      emit('selectDateTimeFrom', readyTimestamp);
    }
    if (selectedDateFrom.value !== null && date === null) {
      const readyPreview = `${selectedTimeFrom.value} ${selectedDateFrom.value}`;
      readyTimestamp = store.parseTimeString(readyPreview, 'HH:mm DD/MM/YYYY');
      emit('selectDateTimeFrom', readyTimestamp);
    }

    // date + time
    const prevFilter = localStorage.getItem('procedures-filter') ? JSON.parse(localStorage.getItem('procedures-filter')!) : {};
    localStorage.setItem('procedures-filter', JSON.stringify({ ...prevFilter, dateTimeFrom: readyTimestamp }));
  } catch (err) {
    throw new Error(`components/procedure/procedureFilterPanelComp: selectDateTimeFrom  => ${err}`);
  }
}

// Функция для выбора времени dateTo (ended to)
function selectDateTimeTo(date: number | null, time: number | null) {
  try {
    if (date !== null) {
      selectedDateTo.value = store.timestampFormat(date, 'DD/MM/YYYY');
    }
    if (time !== null) {
      selectedTimeTo.value = store.timestampFormat(time, 'HH:mm');
    }
    // Если при выполнении этой функции все является null то происходит полное обнуление фильтра
    let readyTimestamp;
    if (date === null && time === null && selectedDateTo.value === null && selectedTimeTo.value === null) {
      return emit('selectDateTimeTo', readyTimestamp);
    }
    if (time === null) {
      const readyPreview = `${selectedDateTo.value}`;
      readyTimestamp = store.parseTimeString(readyPreview, 'DD/MM/YYYY');
      emit('selectDateTimeTo', readyTimestamp);
    }
    if (selectedDateTo.value !== null && date === null) {
      const readyPreview = `${selectedTimeTo.value} ${selectedDateTo.value}`;
      readyTimestamp = store.parseTimeString(readyPreview, 'HH:mm DD/MM/YYYY');
      emit('selectDateTimeTo', readyTimestamp);
    }

    // date + time
    const prevFilter = localStorage.getItem('procedures-filter') ? JSON.parse(localStorage.getItem('procedures-filter')!) : {};
    localStorage.setItem('procedures-filter', JSON.stringify({ ...prevFilter, dateTimeTo: readyTimestamp }));

  } catch (err) {
    throw new Error(`components/procedure/procedureFilterPanelComp: selectDateTimeTo  => ${err}`);
  }
}

// ==========================   WATCH   ==================================
// Отслеживание изменения приходящего массива наименований препаратов
watch(() => props.listDrugNames.length, (newLength) => {
  if (newLength > 0) {
    listDrugNames.value = props.listDrugNames;
  }
});

// Отслеживание изменения приходящего массива с именами пациентов
watch(() => props.listPatientNames.length, (newLength) => {
  if (newLength > 0) {
    listPatientNames.value = props.listPatientNames;
  }
});

// Отслеживание изменения приходящего массива с именами Ответственных за процедуру
watch(() => props.listResponsibleNames.length, (newLength) => {
  if (newLength > 0) {
    listResponsibleNames.value = props.listResponsibleNames;
  }
});

// // ==========================   LIFECYCLE HOOKS   ==================================
</script>

<style scoped>
.procedures-filter-panel {
  min-width: 30%;
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin: 8px 0;
  z-index: 888;
}

.filter-input {
  width: 20%;
}

.filter-datepicker {
  font-size: 6px !important;
  z-index: 999;
}

.datepicker {
  position: relative;
}
</style>
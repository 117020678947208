<template>
    <div class="hospital-rooms-filter-panel">

        <!-- Ввод названия палаты -->
        <v-combobox
            class="filter-input"
            v-model="selectedHospitalRoomName"
            :items="hospitalRoomNames"
            item-title="name"
            item-value="name"
            label="Название палаты" variant="outlined"
            :loading="isLoadingFilteringHospitalRoomName"
            :density="'compact'" :hide-details="true"
            clearable
            @update:model-value="(room: string | null) => selectHospitalRoomName(room?.name)"
        />

        <v-spacer></v-spacer>


        <!-- Выбор Активности отделения -->
        <v-switch v-model="selectedStatus" color="var(--bg-color-default)" label="Неактивные"
            @update:model-value="(status: boolean) => selectStatus(status)" :value="true" hide-details>
        </v-switch>
    </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, defineProps, watch } from 'vue';
import useMainStore from '../../store/mainStore';

const store = useMainStore();

// ==========================   PROPS   ==================================
const props = defineProps<{
    isActiveDepartment: boolean;
    hospitalRoomNames: Array<string | undefined>;
}>();



// ==========================   EMIT   ==================================
const emit = defineEmits<{
    selectStatus: [status: boolean];
    selectHospitalRoomName: [hospitalRoomName: string | null];
}>();


// ==========================   DATA   ==================================
const selectedStatus = ref<boolean>(false);
const selectedHospitalRoomName = ref<string | null>(null);
const isLoadingFilteringHospitalRoomName = ref<boolean>(false);
const payload = store.LazyLoading(300);

// ==========================   WATCH   ==================================
watch(() => props.isActiveDepartment, (newValue) => {
    if (newValue === false) {
        selectedStatus.value = true;
        emit('selectStatus', true);
    }
})

// ==========================   METHODS   ==================================

// Выбор названия палаты
function selectHospitalRoomName(value: string | null) {
    try {
        isLoadingFilteringHospitalRoomName.value = true;
        payload(value, (entries) => {
            let currentMessage: string | null = entries[entries.length - 1];
            if (currentMessage === null) currentMessage = '';
            isLoadingFilteringHospitalRoomName.value = false;
            emit('selectHospitalRoomName', currentMessage);
        });
    } catch (err) {
        throw new Error(`components/hospitalRooms/hospitalRoomsFilterPanelComp: selectHospitalRoomName  => ${err}`);
    }
}

// Функция для выбора Статуса пользователя в списке
function selectStatus(status: boolean) {
    try {
        emit('selectStatus', status);
    } catch (err) {
        throw new Error(`components/hospitalRooms/hospitalRoomsFilterPanelComp: selectStatus  => ${err}`);
    }
}


</script>

<style scoped>
.hospital-rooms-filter-panel {
    min-width: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 1.5rem 0 2rem;
}

.filter-input {
    width: 33%;
}
</style>